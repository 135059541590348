.patient-account-detail-card {
  width: 671px;
  &.mobile {
    max-width: 335px;
  }
  .patient-account-detail-card-content {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 30px;
    .patient-account-detail-card-content-details-container {
      .patient-account-detail-card-grid-detail {
        .patient-account-detail-card-row {
          .patient-account-detail-card-col-icons {
            display: flex;
            flex-direction: column;
            align-items: center;
            .patient-icon-pop-up-button-container {
              width: 100%;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              .patient-account-detail-card-action-button {
                --border-radius: 50%;
                height: 45px;
                width: 45px;
              }
            }
            .patient-account-detail-card-details-icon {
              height: 171px;
              width: 171px;
              margin-bottom: 21px;
              background: url(../../assets/icons/detail-patient.svg) no-repeat
                center center;
            }
          }
          .patient-account-detail-card-col-details {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .patient-account-detail-card-details-container {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              .patient-account-detail-card-sub-detail-container {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                .detail-container {
                  margin-top: 17px;
                  display: flex;
                  align-items: center;
                  &.fee-container {
                    margin-top: 5px;
                  }
                  .md-detail-card-sub-detail-icon {
                    height: 21px;
                    width: 21px;
                    margin-right: 12px;
                    &.gender {
                      background: url(../../assets/icons/gender.svg) no-repeat
                        center center;
                    }
                    &.birthday {
                      background: url(../../assets/icons/birthday.svg) no-repeat
                        center center;
                    }
                    &.mail {
                      background: url(../../assets/icons/mail-icon.svg)
                        no-repeat center center;
                    }
                    &.mobile {
                      background: url(../../assets/icons/mobile-icon.svg)
                        no-repeat center center;
                    }
                    &.freebies {
                      background: url(../../assets/icons/freebies.svg) no-repeat
                        center center;
                    }
                  }
                }
              }
            }
          }
          .patient-account-detail-card-col-button {
            .patient-account-detail-card-action-button {
              --border-radius: 50%;
              height: 45px;
              width: 45px;
            }
          }
        }
      }
      .patient-account-detail-card-grid-schedule {
        margin-top: 20px;
        padding: 0px 40px;
        .patient-account-detail-card-row-schedule-header {
          margin-bottom: 20px;
        }
        .patient-account-detail-card-row-schedule-data {
          .patient-account-detail-card-col-schedule-day {
            display: flex;
            flex-direction: column;
            align-items: center;
            .patient-account-detail-card-schedule-day-container {
              background: rgba(87, 185, 198, 0.25);
              border-radius: 4px;
              height: 43px;
              width: 167px;
              padding-top: 6px;
              margin-bottom: 10px;
              &.not-available {
                background: rgba(196, 196, 196, 0.25) !important;
              }
            }
          }
          .patient-account-detail-card-col-schedule-time-slot {
            display: flex;
            flex-direction: column;
            align-items: center;
            .patient-account-detail-card-schedule-time-slot-label {
              height: 43px;
              width: 167px;
              padding-top: 6px;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
}

.patient-account-assistants-card {
  min-width: 671px;
  background: var(--ion-color-tertiary);
  margin-top: 10px !important;
  &.mobile {
    min-width: 335px;
  }
  .patient-account-assistants-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px;
    .patient-account-assistants-card-add-button {
      height: 30px;
    }
  }

  .patient-account-assistants-card-content {
    padding: 25px;
    .patient-account-assistants-card-row {
      &.light {
        background: var(--ion-color-medium-light);
      }
      .patient-account-assistants-card-col {
        display: flex;
        align-items: center;

        &.button {
          justify-content: flex-end;
        }
      }
    }
  }
}

.patient-account-form-modal {
  --height: auto;
  --width: auto;
  --max-width: 817px;
  --border-radius: 20px;
  --box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  --background: var(--ion-color-tertiary);
  &.mobile {
    --height: 100% !important;
  }
  .modal-wrapper {
    max-width: 100%;
    .ion-page {
      position: relative;
      contain: content;
      max-height: 100%;
      .modal-content {
        overflow: auto;
      }
    }
  }
}

.patient-account-detail-card-popover {
  --box-shadow: 0 0 20px 0 rgba(14, 12, 34, 0.1);
  margin-top: 7px;
  --width: 10%;
  &.mobile {
    --width: 30%;
    left: -20px;
  }
  .popover-content {
    border-radius: 0px;
    background-color: var(--ion-color-tertiary);
    .patient-account-detail-card-list-container {
      display: flex;
      flex-direction: column;
      .patient-account-detail-card-list {
        background-color: var(--ion-color-tertiary);
        .patient-account-detail-card-item-container {
          width: 100%;
          background-color: var(--ion-color-tertiary) !important;
          .patient-account-detail-card-dropdown-modal-item {
            --inner-padding-end: 0;
            --padding-start: 0;
            --background: var(--ion-color-tertiary);
            .patient-account-detail-card-dropdown-label {
              display: flex;
              align-items: center;
              .patient-account-detail-card-dropdown-modal-item-icon {
                margin-left: 10px;
                margin-right: 10px;
                height: 21px;
                width: 21px;
                opacity: 0.2;
              }
            }
          }
        }
      }
    }
  }
}
