.mb-header-toolbar {
  height: 76px;
  display: flex;
  align-items: center;
  flex-direction: row;
  .mb-header-side-nav-button {
    position: absolute;
    left: 0.5%;
    top: 20%;
    height: 45px;
    width: 45px;
    --border-radius: 50%;
    .mb-header-side-menu-icon {
      background: url("../../assets/icons/burger-icon.svg") no-repeat center
        center;
      height: 22px;
      width: 16px;
    }
  }

  .mb-header-logo {
    cursor: pointer;
    background: url("../../assets/mb-header-logo.svg") no-repeat center center;
    height: 61px;
    width: 211px;
    margin-left: 70px;
    align-self: flex-end;
  }
  .mb-header-mobile-menu {
    display: none;
  }
  .mb-nav-button {
    --inner-padding-end: 0;
    width: auto;
    margin-right: 30px;
    .nav-button-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 70px;
      width: 100%;
      justify-content: space-between;
      .mb-nav-dropdown-label {
        padding-left: 20px;
        padding-right: 20px;
        max-width: 170px;
        overflow: hidden;
        text-overflow: ellipsis;
        pointer-events: none;
      }
      .mb-nav-button-drop-down-icon {
        height: 14px;
        width: 14px;
        right: 0;
        pointer-events: none;
        padding-right: 20px;
      }
    }
  }
  .mb-header-links-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    height: 76px;
    .mb-header-book-list-icon {
      width: 17px;
      height: 20px;
      background: url("../../assets/icons/book-list.svg") no-repeat center
        center;
    }
    .mb-header-wait-list-icon {
      width: 17px;
      height: 20px;
      background: url("../../assets/icons/wait-list.svg") no-repeat center
        center;
    }
    .mb-header-book-list-counter {
      width: 19px;
      height: 19px;
      color: white;
      background: red;
      border-radius: 50%;
      position: absolute;
      top: 0px;
      right: -13px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .mb-header-nav-container {
      cursor: pointer;
      height: 76px;
      text-decoration: none;
      margin-right: 50px;
      display: flex;
      flex-direction: column;
      padding-top: 24px;
    }
    .active {
      color: var(--ion-color-tertiary) !important;
    }
  }
}
.mb-header-active {
  padding-top: 23px;
  border-bottom: 4px solid var(--ion-color-tertiary);
}
.mb-nav-popover {
  .mb-nav-list {
    .mb-nav-item-container {
      .mb-nav-dropdown-modal-item {
        .mb-nav-dropdown-label {
          display: flex;
          align-items: center;
          .mb-nav-dropdown-modal-item-icon {
            margin-right: 10%;
            height: 21px;
            width: 21px;
            opacity: 0.2;
            &.my-account {
              background: url(../../assets/icons/my-account-nav.svg) no-repeat
                center center;
            }
            &.logout {
              background: url(../../assets/icons/logout-nav.svg) no-repeat
                center center;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .mb-header-toolbar {
    .mb-header-logo {
      cursor: pointer;
      background: url("../../assets/mb-header-logo-mobile.svg") no-repeat center
        center;
      height: 57px;
      width: 51px;
      margin-bottom: 5px;
    }

    .mb-nav-button {
      margin-right: unset;
    }
  }

  .mb-nav-popover {
    --width: 60%;
  }
}
