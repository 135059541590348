.pre-reg-card-container {
  height: 100%;
  width: 100%;
  min-height: 50px;
  padding: 36px 44px;
  .pre-reg-container {
    width: 100%;
    height: 100%;
  }
  .subs-container {
    width: 100%;
    height: 100%;
  }
}
