@import "../../theme/shared.scss";

.mb-container-main-container {
  height: 100%;
  width: 100%;
  .mb-container-grid {
    height: 100%;
    width: 100%;
    .mb-container-row {
      height: 100%;
      width: 100%;
      .mb-container-col-side-menu {
        height: auto;
        width: 100%;
        background: var(--ion-color-white);
        z-index: 1;
        border-right: 1px solid var(--ion-color-medium);
        .mb-side-nav-list {
          transition: 3s;
          background: var(--ion-color-white);
          .mb-side-nav-item-container {
            background: var(--ion-color-white);
            .mb-side-nav-item {
              background: var(--ion-color-white);
              .mb-side-nav-label {
                display: flex;
                align-items: center;
                .mb-side-nav-item-icon {
                  margin-left: 24px;
                  margin-right: 19px;
                  height: 21px;
                  width: 21px;
                  opacity: 0.2;
                  &.home-active {
                    background: url(../../assets/icons/active-home-nav.svg)
                      no-repeat center center;
                  }
                  &.analytics {
                    background: url(../../assets/icons/analytics-nav.svg)
                      no-repeat center center;
                  }
                  &.analytics-active {
                    background: url(../../assets/icons/analytics-nav-active.svg)
                      no-repeat center center;
                  }
                  &.department {
                    background: url(../../assets/icons/department-nav.svg)
                      no-repeat center center;
                  }
                  &.department-active {
                    background: url(../../assets/icons/active-department-nav.svg)
                      no-repeat center center;
                  }
                  &.my-appointments-active {
                    background: url(../../assets/icons/active-my-appointments-nav.svg)
                      no-repeat center center;
                  }
                  &.my-account-active {
                    background: url(../../assets/icons/active-my-account-nav.svg)
                      no-repeat center center;
                  }
                  &.home {
                    background: url(../../assets/icons/home-nav.svg) no-repeat
                      center center;
                  }
                  &.my-appointments {
                    background: url(../../assets/icons/my-appointments-nav.svg)
                      no-repeat center center;
                  }
                  &.scan-qr {
                    background: url(../../assets/icons/scan-qr.svg) no-repeat
                      center center;
                  }
                  &.scan-qr-active {
                    background: url(../../assets/icons/scan-qr-active.svg)
                      no-repeat center center;
                  }
                  &.search {
                    background: url(../../assets/icons/search.svg) no-repeat
                      center center;
                  }
                  &.search-active {
                    background: url(../../assets/icons/search-active.svg)
                      no-repeat center center;
                  }
                  &.my-account {
                    background: url(../../assets/icons/my-account-nav.svg)
                      no-repeat center center;
                  }
                  &.doctor-account {
                    background: url(../../assets/icons/doctor-account-nav.svg)
                      no-repeat center center;
                  }
                  &.doctor-account-active {
                    background: url(../../assets/icons/active-doctor-account-nav.svg)
                      no-repeat center center;
                  }
                  &.logout {
                    background: url(../../assets/icons/logout-nav.svg) no-repeat
                      center center;
                  }
                }
              }
            }
          }
        }
      }
      .mb-container-col-content {
        height: auto;
        width: 100%;
        margin: 0 auto;
      }
    }
  }

  .mb-container-doctor-out-of-office-banner {
    background: var(--ion-color-warning);
    display: flex;
    position: absolute;
    width: 100%;
    bottom: 0;
    z-index: 1;
    justify-content: space-between;

    .mb-container-doctor-out-of-office-banner-details-container {
      display: flex;
      .mb-container-doctor-out-of-office-banner-icon {
        width: 43px;
        height: 38px;
        opacity: 0.4;
        padding: 28px 48px;
      }
      .mb-container-doctor-out-of-office-banner-description {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
  .mb-container-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 40px;
    height: 100%;
  }
}

@media (max-width: 414px) {
  .mb-container-main-container {
    .mb-container-content-container {
      padding: 15px;
    }
  }
}

.appointment-reminder-dialog {
  --height: auto;
  --width: auto;
  --max-width: 560px;
  --border-radius: 20px;
  --box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  --background: var(--ion-color-tertiary);
  ion-backdrop {
    background: #104c82 !important;
  }
  .modal-wrapper {
    max-width: 100%;
    width: 560px;
    .ion-page {
      position: relative;
      contain: content;
      max-height: 90vh;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  .appointment-reminder-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px;
    text-align: center;
    .appointment-reminder-label {
      margin-top: 5px;
    }
    .appointment-reminder-icon {
      background: url("../../assets/appointment-reminder.svg") no-repeat center
        center;
      height: 182px;
      width: 182px;
      margin: 20px 0px;
    }
    .appointment-reminder-button {
      width: 100%;
      margin-top: 30px;
      filter: drop-shadow(0px 8px 21px rgba(87, 185, 198, 0.5));
    }
  }
}
