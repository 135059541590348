.patient-account-card {
  width: 600px;
  --background: var(--ion-color-tertiary);
  &.mobile {
    width: 100%;
    min-width: 330px;
  }
  .patient-account-card-header {
    display: flex;
    flex-direction: column;
    padding: 30px;
    border-bottom: 1px solid var(--ion-color-medium);
  }
  .patient-account-form-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px 30px 15px 30px;
    overflow-y: auto;
    .patient-account-form-description-container {
      display: flex;
      flex-direction: column;
      padding: 0 15px;
    }
    .patient-account-form-container-grid {
      width: 100%;
      margin-top: 10px;
      .patient-account-form-container-col {
        padding: 0 15px 30px 15px;
        &.last {
          padding-bottom: 25px;
        }
        .patient-account-form-id {
          height: 200px;
          cursor: pointer;
        }
        .patient-account-form-province-container {
          width: 240px;
          .patient-account-form-dropdown {
            border-bottom: 1px solid var(--ion-color-primary);
          }
        }

        .patient-account-form-doctor-container {
          width: 100%;
          .patient-account-form-dropdown {
            border-bottom: 1px solid var(--ion-color-primary);
          }
        }

        .patient-account-form-preferred-doctor-container {
          width: 509px;
        }
        .patient-account-form-password-item {
          display: flex;
          flex-direction: row;
          align-items: center;
          --inner-padding-end: 0px;
          --background: var(--ion-color-tertiary);
          .patient-account-form-password-input {
            width: 100%;
            border: 1px solid var(--ion-color-medium);
            margin-top: 8px;
            --padding-start: 15px !important;
          }
          .patient-account-form-change-password-button {
            height: 30px;
            position: absolute;
            right: 0;
          }
        }
      }
    }
  }

  .patient-account-card-footer {
    display: flex;
    padding: 35px 30px 30px 30px;
    justify-content: flex-end;
    .patient-account-cancel-button {
      --border-width: 1px;
      --border-style: solid;
      --border-color: var(--ion-color-medium);
      height: 30px;
      width: 100px;
    }
    .patient-account-submit-button {
      height: 30px;
      width: 100px;
      &.mobile {
        width: 100% !important;
      }
    }
  }
}

a {
  cursor: pointer;
  color: #1b91ff !important;
}

@media (max-width: 414px) {
  .patient-account-card {
    width: 90%;
    .patient-account-card-header {
      padding: 15px 15px 0px 15px;
    }
    .patient-account-form-container {
      width: 100%;
      padding: 0px 15px 15px 15px;
      .patient-account-form-description-container {
        padding: 0 15px;
      }
      .patient-account-form-container-grid {
        width: 100%;
        .patient-account-form-container-col {
          padding: 0 0 15px;
          &.last {
            padding-bottom: 25px;
          }
          .patient-account-form-province-container {
            width: 100%;
          }
          .patient-account-form-preferred-doctor-container {
            width: 100%;
          }
        }
      }
    }

    .patient-account-card-footer {
      display: flex;
      flex-direction: column;
      padding: 35px 30px 30px 30px;
      justify-content: space-between;
      .patient-account-cancel-button {
        --border-width: 1px;
        --border-style: solid;
        --border-color: var(--ion-color-medium);
        height: 30px;
        width: 100%;
      }
      .patient-account-submit-container {
        display: flex;
        flex-direction: column-reverse;
        .patient-account-submit-button {
          height: 30px;
          width: 100%;
        }
      }
    }
  }
}
