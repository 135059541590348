.login-options-bg-card {
  width: 100%;
  height: 100%;
  .login-options-grid {
    width: 100%;
    height: 100%;
  }
  .login-options-row {
    width: 100%;
    height: 100%;
  }
  .login-options-bg-card-container {
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // justify-content: flex-start;
    width: 100%;
    height: 100%;
    padding-top: 50px;
    &.mobile {
      padding: 20px !important;
    }
    .login-options-bg-image {
      width: 100%;
      height: 100%;
      background: url(../../assets/login-img.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      &.mobile-bg {
        position: absolute;
        z-index: 0;
        bottom: 250px;
      }
    }
    .login-options-card-col {
      display: flex;
      flex-direction: row;

      .login-options-card {
        max-width: 529px;
        max-height: 650px;
        &.mobile {
          width: 100%;
          margin-top: 150px;
          margin-bottom: 20px;
        }

        .login-options-card-content {
          display: flex;
          flex-direction: column;
          text-align: center;
          padding: 32px 15px;
          max-width: 529px;
          // &.mobile {
          //   padding: 0px 15px !important;
          // }
          .login-options-text-header {
            margin-bottom: 30px;
            margin-top: 20px;
            &.mobile {
              margin: 10px 0px !important;
            }
          }
          .login-options-text-description {
            margin-bottom: 40px;
            width: 75%;
            align-self: center;
          }
          .login-options-card-logo {
            background: url(../../assets/mb-card-logo.svg) no-repeat center;
            width: 396px;
            height: 100px;
            margin-bottom: 12px;
            align-self: center;

            &.mobile {
              margin: 0 auto 12px;
              background: url(../../assets/mobile-mb-card-logo.svg) no-repeat
                center;
              width: 280px;
              height: 76px;
            }
          }
          .login-options-details {
            display: flex;
            flex-direction: column;
            text-align: center;
            padding: 0 15px;
            .login-options-sub-label {
              margin-top: 10px;
            }
            .login-options-logo-container {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-evenly;
              margin-top: 14px;
              .cnag-ph {
                background: url(../../assets/cnag-logo.svg) no-repeat center;
                height: 56px;
                width: 180px;
              }
              .login-options-project-cancer-commission {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 15px;
                .login-options-project-cancer-label {
                  margin-right: 10px;
                }
                .cancer-commission {
                  background: url(../../assets/cancer-commission.svg) no-repeat
                    center;
                  height: 71px;
                  width: 190px;
                  &.mobile {
                    background: url(../../assets/cancer-commission-mobile.svg)
                      no-repeat center;
                    height: 49px;
                    width: 153px;
                  }
                }
              }
            }

            .login-options-card-header-label {
              margin-top: 20px;
              text-align: center;
            }
            .login-options-card-description-label {
              margin-top: 21px;
              line-height: 19px;
              text-align: center;
            }

            .login-options-gmail-button {
              margin-top: 12px;
              height: 43px;
              --background: #ea4335;
              .gmail-icon {
                margin-right: 25px;
                width: 28px;
                height: 21px;
                background: url(../../assets/icons/gmail-btn-logo.svg) no-repeat
                  center center;
              }
            }
            .login-options-create-button {
              margin-top: 12px;
              height: 43px;
              filter: drop-shadow(0px 8px 21px rgba(87, 185, 198, 0.5));
            }
            .login-options-divider {
              margin-top: 30px;
              margin-bottom: 20px;
              width: 100%;
              background: url(../../assets/login-divider.svg) no-repeat center
                center;
            }
            .login-options-agreement-label {
              margin: 60px 40px 3px 40px;
              &.mobile {
                margin: 15px 0px !important;
              }
              .user-agreement-link {
                margin-left: 2px;
                height: 14px;
              }
              .policies-link {
                margin-left: 2px;
                height: 14px;
              }
            }
          }
        }
      }
    }
  }
}
