.landing-slides {
  height: 100% !important;
  &.mobile {
    height: auto !important;
  }
}
.tab-container {
  .swiper-pagination-bullet {
    border-radius: 0px !important;
    height: 6px;
    width: 20px;
    display: none;
  }
  .slider-slide {
    height: 100vh !important;
  }
  ion-content ion-toolbar {
    --background: translucent;
  }
  pad-10 {
    padding: 40px;
  }
  .pt-20 {
    // padding-top: 80px !important;
  }

  ion-slides {
    height: auto;
    padding-bottom: 0px !important;
  }
  ion-padding {
    padding: 400px !important;
  }
  .abs {
    position: absolute;
    z-index: 90;
    bottom: 80px;
    width: 100%;
    left: 120px;
  }

  body,
  p {
    font-family: "Open Sans", sans-serif !important;
  }
  .pointer {
    cursor: pointer;
    user-select: none;
  }

  .bord-rad-0 {
    border-radius: 0px;
  }

  .thumbs {
    width: 180px !important;
    min-width: 0px !important;
    font-size: 16px !important;
    transition: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }

  @media only screen and (max-width: 600px) {
    .thumbs {
      width: 100% !important;
      min-width: 0px !important;
      font-size: 16px !important;
    }

    .abs {
      position: relative !important;
      z-index: 90 !important;
      bottom: 0px !important;
      width: 100% !important;
      left: 0px !important;
      transition: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    }
  }
  //  and (max-width: 900px)
  @media (max-height: 770px) {
    .abs {
      bottom: 10px;
      left: 10px;
    }
    .cards-footer-container {
      .three-cards-container {
        margin-left: 20px;
        margin-bottom: 20px;
        // position: absolute;
        // top: 380px;
        // left: 10px;
        z-index: 9999;
      }
    }
  }

  a {
    text-decoration: none;
  }

  .mr-50 {
    margin-right: 50px !important;
    margin-bottom: 10px !important;
  }
  .mr-10 {
    margin-right: 10px !important;
    margin-bottom: 10px !important;
  }
  .pb-0 {
    padding-bottom: 0px !important;
  }
  .ion-content {
    padding-bottom: 0px;
  }
  .word {
    word-wrap: break-word !important;
  }
}

.cards-footer-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  max-height: 200px;
  .three-cards-container {
    margin-left: 120px;
    margin-bottom: 50px;
    z-index: 9999;
  }
}

.tab3-footer {
  // position: absolute;
  // bottom: 0;
}
