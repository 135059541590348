.mb-dashboard-card {
  background: var(--ion-color-tertiary);
  width: 347px;
  height: 108px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  &.mobile {
    margin: 10px 0px !important;
  }
  .mb-dashboard-card-content-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    max-width: 273px;
    .mb-dashboard-card-icon {
      height: 59px;
      width: 59px;
      &.doctor {
        background: url("../../assets/icons/doctor-card-icon.svg") no-repeat
          center center;
      }
      &.service {
        background: url("../../assets/icons/service-card-icon.svg") no-repeat
          center center;
      }
      &.ambulatory {
        background: url("../../assets/icons/ambulatory-card-icon.svg") no-repeat
          center center;
      }
      &.booked {
        background: url("../../assets/icons/booked-card-icon.svg") no-repeat
          center center;
      }
      &.cancelled {
        background: url("../../assets/icons/cancelled-card-icon.svg") no-repeat
          center center;
      }
    }
    .mb-dashboard-card-details-container {
      display: flex;
      flex-direction: column;
      margin-left: 25px;
      .message {
        margin-top: 5px;
      }
    }
  }
}
