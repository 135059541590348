@import "../../theme/shared.scss";

.mb-appointment-list-container {
  .mb-appointment-list-card {
    min-width: 1045px;
    width: 100%;
    min-height: 215px;
    height: 100%;
    margin: 15px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .mb-appointment-list-grid {
      height: 100%;
      .mb-appointment-list-row {
        height: 100%;
        .mb-appointment-list-icon-col {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          .mb-appointment-list-content-checkbox {
            align-self: center;
            margin: 20px;
            width: 27px;
          }
          .mb-appointment-list-icon-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 100%;
            width: 100%;

            .mb-appointment-list-card-icon {
              margin-bottom: 25px;
              &.patient {
                background: url("../../assets/icons/patient-icon.svg") no-repeat
                  center center;
                width: 70px;
                height: 71px;
              }
              &.doctor {
                background: url("../../assets/icons/doctor-card-icon.svg")
                  no-repeat center center;
                width: 58px;
                height: 58px;
              }
              &.out-patient {
                background: url("../../assets/icons/ambulatory-card-icon.svg")
                  no-repeat center center;
                width: 59px;
                height: 59px;
              }
            }
            .mb-appointment-list-status {
              min-height: 25px;
              max-width: 115px;
              border-radius: 4px;
              width: 100%;
              font-size: $wcH4FontSize;
              font-weight: 700;
              text-align: center;
              padding-top: 2px;
              &.booked {
                color: var(--ion-color-warning);
                background-color: rgba(#ffa403, 0.25);
              }
              &.scheduled {
                color: var(--ion-color-warning);
                background-color: rgba(#ffa403, 0.25);
              }
              &.today {
                color: var(--ion-color-primary);
                background-color: rgba(#57b9c6, 0.25);
              }
              &.cancelled {
                color: var(--ion-color-danger);
                background-color: rgba(#cc212a, 0.25);
              }
              &.done {
                color: var(--ion-color-success);
                background-color: rgba(#57c6aa, 0.25);
              }
            }
          }
        }
        .mb-appointment-list-details-col {
          display: flex;
          flex-direction: column;
          .mb-appointment-list-patient-name {
          }
          .mb-appointment-list-hospital-container {
            margin: 5px 0px;
          }
          .mb-appointment-list-schedule-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            .mb-appointment-list-date-container {
              display: flex;
              flex-direction: row;
              align-items: center;
              .mb-appointment-list-date-label {
                padding-top: 2px;
                margin: 0px 10px;
              }
            }
            .mb-appointment-list-time-container {
              display: flex;
              flex-direction: row;
              align-items: center;
              .mb-appointment-list-time-label {
                padding-top: 2px;
                margin: 0px 10px;
              }
            }
          }
          .mb-appointment-list-fee-label {
            margin-top: 30px;
            .mb-appointment-list-with-result-covid-container {
              margin-bottom: 15px;
              display: flex;
              flex-direction: row;
              align-items: center;
              width: 100%;
              .mb-appointment-list-with-result-covid-icon {
                margin-right: 10px;
                background: url(../../assets/icons/covid-label-icon.svg)
                  no-repeat center center;
                height: 21px;
                width: 20px;
              }
            }
          }
        }
        .mb-appointment-list-button-col {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-end;
          padding-right: 40px;
          .mb-appointment-list-button-container {
            .mb-appointment-list-button {
              border-radius: 4px;
              min-height: 43px;
              min-width: 93px;
              margin: 0px 3px;
              &.cancel {
                border: 1px solid var(--ion-color-danger);
              }
              &.view {
                border: 1px solid var(--ion-color-primary);
              }

              &.confirm-arrival {
                border: 1px solid var(--ion-color-primary);
                width: 150px;
              }
            }
          }
        }
      }
    }
  }
  .mb-appointment-list-no-appointments-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 95px;
    .no-appointments {
      &.doctor {
        background: url("../../assets/no-appointments-doctor.svg") no-repeat
          center center;
        width: 203px;
        height: 171px;
      }
      &.patient {
        background: url("../../assets/no-appointments-patient.svg") no-repeat
          center center;
        width: 223px;
        height: 171px;
      }
      &.hospital {
        background: url("../../assets/no-appointments-hospital.svg") no-repeat
          center center;
        width: 223px;
        height: 171px;
      }
    }
    .no-appointments-label {
      margin: 30px 0px 10px 0px;
      text-align: center;
    }
  }
}

@media (max-width: 1024px) {
  .mb-appointment-list-container {
    .mb-appointment-list-card {
      min-width: unset;
      padding: 20px;
      .mb-appointment-list-grid {
        height: 100%;
        .mb-appointment-list-row {
          height: 100%;
          display: flex;
          flex-direction: column;
          width: 100%;
          .mb-appointment-list-icon-col {
            height: 100%;
            display: flex;
            align-items: center;
            flex-direction: row !important;
            width: 100% !important;
            .mb-appointment-list-content-checkbox {
              align-self: center;
              width: 27px;
            }
            .mb-appointment-list-icon-container {
              display: flex;
              flex-direction: row;
              align-items: center;
              height: 100%;
              width: 100%;
              .mb-appointment-list-card-icon {
                margin-bottom: 0px !important;
                margin-right: 15px;
                &.patient {
                  background: url("../../assets/icons/patient-icon.svg")
                    no-repeat center center;
                  width: 70px;
                  height: 71px;
                }
                &.doctor {
                  background: url("../../assets/icons/doctor-card-icon.svg")
                    no-repeat center center;
                  width: 58px;
                  height: 58px;
                }
                &.out-patient {
                  background: url("../../assets/icons/ambulatory-card-icon.svg")
                    no-repeat center center;
                  width: 59px;
                  height: 59px;
                }
              }
              .mb-appointment-list-status {
                min-height: 25px;
                max-width: 115px;
                border-radius: 4px;
                width: 100%;
                font-size: $wcH4FontSize;
                font-weight: 700;
                text-align: center;
                padding-top: 2px;
                &.booked {
                  color: var(--ion-color-warning);
                  background-color: rgba(#ffa403, 0.25);
                }
                &.scheduled {
                  color: var(--ion-color-warning);
                  background-color: rgba(#ffa403, 0.25);
                }
                &.today {
                  color: var(--ion-color-primary);
                  background-color: rgba(#57b9c6, 0.25);
                }
                &.cancelled {
                  color: var(--ion-color-danger);
                  background-color: rgba(#cc212a, 0.25);
                }
                &.done {
                  color: var(--ion-color-success);
                  background-color: rgba(#57c6aa, 0.25);
                }
              }
            }
          }
          .mb-appointment-list-details-col {
            display: flex;
            flex-direction: column;
            .mb-appointment-list-patient-name {
              margin: 15px 0;
              // text-align: center;
            }
            .mb-appointment-list-hospital-container {
              margin: 5px 0px;
            }
            .mb-appointment-list-schedule-container {
              flex-direction: column;
              align-items: flex-start;
              .mb-appointment-list-date-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                .mb-appointment-list-date-label {
                  padding-top: 2px;
                  margin: 0px 10px;
                }
              }
              .mb-appointment-list-time-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                .mb-appointment-list-time-label {
                  padding-top: 2px;
                  margin: 0px 10px;
                }
              }
            }
            .mb-appointment-list-fee-label {
              margin-top: 30px;
              .mb-appointment-list-with-result-covid-container {
                margin-bottom: 15px;
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 100%;
                .mb-appointment-list-with-result-covid-icon {
                  margin-right: 10px;
                  background: url(../../assets/icons/covid-label-icon.svg)
                    no-repeat center center;
                  height: 21px;
                  width: 20px;
                }
              }
            }
          }
          .mb-appointment-list-button-col {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            padding-right: unset;
            .mb-appointment-list-days-counter {
              margin-top: 10px;
            }
            .mb-appointment-list-button-container {
              width: 100%;
              display: flex;
              flex-direction: row;
              .mb-appointment-list-button {
                border-radius: 4px;
                width: 100%;
                margin-top: 15px;
                &.cancel {
                  border: 1px solid var(--ion-color-danger);
                }
                &.view {
                  border: 1px solid var(--ion-color-primary);
                }

                &.confirm-arrival {
                  border: 1px solid var(--ion-color-primary);
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
    .mb-appointment-list-no-appointments-container {
      .no-appointments {
        &.doctor {
          background: url("../../assets/no-appointments-doctor.svg") no-repeat
            center center;
          width: 203px;
          height: 171px;
        }
        &.patient {
          background: url("../../assets/no-appointments-patient.svg") no-repeat
            center center;
          width: 223px;
          height: 171px;
        }
        &.hospital {
          background: url("../../assets/no-appointments-hospital.svg") no-repeat
            center center;
          width: 223px;
          height: 171px;
        }
        &.security {
          background: url("../../assets/no-appointments-hospital.svg") no-repeat
            center center;
          width: 223px;
          height: 171px;
        }
      }
      .no-appointments-label {
        margin: 30px 0px 10px 0px;
        text-align: center;
        width: 100%;
        min-width: 200px;
      }
      .no-appointments-sub-label {
        text-align: center;
        width: 100%;
        min-width: 250px;
      }
    }
  }
}

.mb-appointment-name-container {
  display: flex;
  flex-direction: row;
}
