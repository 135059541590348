.doctor-account-card {
  min-width: 600px;
  &.mobile {
    width: 100%;
    min-width: 330px;
  }
  .doctor-account-card-header {
    display: flex;
    flex-direction: column;
    padding: 25px 25px 20px;
    border-bottom: 1px solid var(--ion-color-medium);
    .doctor-account-card-header-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .doctor-account-vacation-mode-label {
        display: flex;
        align-items: center;
        ion-toggle {
          margin-left: 10px;
        }
      }

      &.mobile {
        flex-direction: column;
        align-items: start;
      }
    }
  }
  .doctor-account-form-card-container {
    width: 100%;
    max-height: 600px;
    overflow-y: auto;
    .doctor-account-form-user-details {
      &.mobile {
        display: flex;

        &.schedule-mode {
          display: none;
        }
      }
      .doctor-account-form-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0px 30px 0px 30px;
        .doctor-account-form-description-container {
          display: flex;
          flex-direction: column;
          padding: 0 15px;
        }
        .doctor-account-form-container-grid {
          width: 100%;
          margin-top: 10px;
          .doctor-account-form-container-col {
            padding-bottom: 30px;

            &.left {
              padding-right: 15px;
            }

            &.right {
              padding-left: 15px;
            }

            &.password {
              padding-top: 3px;
            }

            .doctor-account-form-select-container {
              width: 100%;
            }

            .doctor-account-form-container-fee-input {
              height: 38px;
              border: 1px solid var(--ion-color-medium-shade);
              border-radius: 4px;
              align-items: center;
              padding-left: 10px;
            }

            .doctor-account-form-password-item {
              display: flex;
              flex-direction: row;
              align-items: center;
              --inner-padding-end: 0px;
              --background: var(--ion-color-tertiary);

              .doctor-account-form-password-input {
                width: 100%;
                border-bottom: 1px solid var(--ion-color-primary);
                margin-top: 8px;
                --padding-start: 15px !important;
              }
              .doctor-account-form-change-password-button {
                height: 30px;
                position: absolute;
                right: 0;
                margin-right: 5px;
              }
            }
          }
        }
      }
    }

    .doctor-account-form-break-time-schedule,
    .doctor-account-form-schedule-estimated-appointment-length,
    .doctor-account-form-schedule-container {
      margin-left: 30px;
      margin-right: 30px;
      text-align: start;
      .schedule-label {
        &.mobile {
          padding-left: 15px;
        }
      }
      &.doctor-account-form-break-time-schedule {
        margin-bottom: 40px;
      }

      &.doctor-account-form-schedule-estimated-appointment-length {
        margin-bottom: 40px;
      }

      &.mobile {
        margin-left: 0px !important;
        margin-right: 0px !important;
      }
      .doctor-account-form-row-schedule {
        &.mobile {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
      }
      .doctor-account-form-schedule-item {
        width: 140px;
        &.mobile {
          width: 155px;
        }
        .doctor-account-form-schedule-label {
          margin-left: 17px;
        }
      }

      .doctor-account-form-schedule-col {
        align-self: center;
        padding-right: 20px;

        .doctor-account-form-schedule-col-select-container {
          width: 150px;
          max-width: 150px;
        }

        .appointment-length-options {
          border: 1px solid var(--ion-color-medium);
          border-radius: 4px;
          width: 100%;
          --padding-start: 5px;
        }

        .doctor-account-form-time-slot-input {
          height: 38px;
          border: 1px solid var(--ion-color-medium-shade);
          border-radius: 4px;
          align-items: center;
          padding-left: 10px;
        }
      }

      // .doctor-account-form-schedule-period-container {
      //   display: flex;
      //   flex-direction: row;
      //   justify-content: space-evenly;
      //   .doctor-account-form-schedule-slots-item {
      //     margin-bottom: 2px;
      //     margin-right: 3px;
      //     --inner-padding-end: 0;
      //     .doctor-account-form-schedule-slots-input {
      //       border: 1px solid var(--ion-color-medium);
      //       height: 23px;
      //       width: 45px !important;
      //       margin-left: 10px;
      //     }
      //   }
      // }
    }

    .doctor-account-card-schedule-container {
      margin-left: 5px;
      margin-right: 5px;
      text-align: start;

      &.mobile {
        display: none;

        &.schedule-mode {
          display: block;
        }
      }

      .doctor-account-form-container-grid {
        width: 100%;
        margin-top: 10px;
        .doctor-account-form-container-col {
          padding: 25px;

          .doctor-account-form-select-container {
            width: 100%;
          }
        }
      }

      .schedule-label {
        margin-left: 25px;
      }

      .doctor-account-card-schedule-item {
        width: 140px;
        .doctor-account-card-schedule-label {
          margin-left: 17px;
        }
      }
      .doctor-account-card-schedule-period-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        .doctor-account-card-schedule-slot-label {
          margin-right: 10px;
        }
        .doctor-account-card-schedule-slots-item {
          margin-bottom: 2px;
          --inner-padding-end: 0;
          .doctor-account-card-schedule-slots-input {
            border: 1px solid var(--ion-color-medium);
            height: 23px;
            width: 45px !important;
            margin-left: 10px;
          }
        }
      }
    }
  }
  .doctor-account-card-footer {
    display: flex;
    padding: 35px 30px 30px 30px;
    justify-content: space-between;
    &.initial-setup {
      justify-content: flex-end;
    }
    .doctor-account-cancel-button {
      --border-width: 1px;
      --border-style: solid;
      --border-color: var(--ion-color-medium);
      height: 30px;
      width: 100px;
    }
    .doctor-account-submit-button {
      height: 30px;
      // width: 100px;
    }
    &.mobile {
      padding-top: 0;
      flex-direction: column;
      .doctor-account-submit-button,
      .doctor-account-mode-button,
      .doctor-account-cancel-button,
      .doctor-account-submit-button {
        width: 100%;
        height: 30px;
      }
    }
  }
}

.doctor-account-assistants-card {
  min-width: 600px;
  background: var(--ion-color-tertiary);
  margin-top: 10px !important;

  .doctor-account-assistants-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px;
    .doctor-account-assistants-card-add-button {
      height: 30px;
    }
  }

  .doctor-account-assistants-card-content {
    padding: 25px;
    .doctor-account-assistants-card-col {
      display: flex;
      align-items: center;

      &.button {
        justify-content: flex-end;
      }
    }
  }
}

a {
  cursor: pointer;
  color: #1b91ff !important;
}
