.hospital-analytics-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  .hospital-analytics-total-grid {
    width: 100%;
    .hospital-analytics-view-type-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      width: 100%;
      border-bottom: 1px solid var(--ion-color-medium-light);

      .hospital-analytics-view-type {
        min-width: 100px;
        border-bottom: none;
        padding: 0 20px;
        &.active {
          border-bottom: 4px solid var(--ion-color-primary);
        }
      }
    }
    .hospital-analytics-total-card {
      .hospital-analytics-total-card-content {
        display: flex;
        padding: 30px 0;
        .hospital-analytics-total-icon {
          background: url("../../assets/icons/analytics-patient.svg") no-repeat
            center center;
          width: 67px;
          height: 67px;
          padding: 0 25px;
          &.patient {
            background: url("../../assets/icons/analytics-patient.svg")
              no-repeat center center;
          }
          &.doctor {
            background: url("../../assets/icons/analytics-doctor.svg") no-repeat
              center center;
          }
          &.out-patient {
            background: url("../../assets/icons/analytics-outpatients-made.svg")
              no-repeat center center;
          }
          &.consultation {
            background: url("../../assets/icons/analytics-consultations-made.svg")
              no-repeat center center;
          }
        }

        .hospital-analytics-total-container {
          display: flex;
          flex-direction: column;
          padding: 0 30px 0 0;
          width: 100%;
        }
      }

      .hospital-analytics-doughnut-card-content {
        display: flex;
        flex-direction: column;
        padding: 50px;
        .out-patient-booked-vs-capacity {
          width: 180px;
          height: 180px;
        }

        .hospital-analytics-bar-charts-details-and-filters-content {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          .hospital-analytics-bar-charts-details-labels-content {
            display: flex;
            flex-direction: column;
          }
          .hospital-analytics-bar-charts-filter-segment {
            max-width: 420px;
            border: 1px solid var(--ion-color-medium);
            border-radius: 4px;
            .hospital-analytics-bar-charts-filter-segment-button {
              --indicator-color: unset;
              border: 1px solid var(--ion-color-medium);
            }
          }
        }
      }
    }

    .hospital-analytics-todays-stat-label {
      border-radius: 4px;
    }

    .hospital-analytics-view-type-options-container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .hospital-analytics-hospital-filter-container {
        margin: 0 3px;
        width: 250px;

        .hospital-analytics-hospital-filter-drop-down-options {
          border: 1px solid var(--ion-color-medium);
          border-radius: 4px;
          margin: 0;
          height: 100%;
          --padding-start: 10px !important;
          --padding-end: 10px !important;
        }
      }
    }
  }
}
