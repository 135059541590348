.pre-reg-card {
  max-width: 449px;
  &.mobile {
    // max-height: 349px !important;
    height: 100%;
  }
}

.bkg-gray {
  background-color: #fafafa;
  .preregistration {
    // margin: 20px 60px !important;
    box-shadow: -1px 3px 5px 0px rgba(61, 61, 61, 0.29);
    border-radius: 20px;
    min-width: 380px;
    .pre-reg-input-containers {
      padding: 30px 40px 20px 40px;
      width: auto;
      &.mobile {
        padding: 0px !important;
        margin: 20px !important;
      }
    }
  }
  .pre-reg-row {
    height: auto;
    background-color: #09acbd !important;
  }
  .pre-reg-how-easy-row {
    * {
      scroll-behavior: smooth;
    }

    // .tab-container {
    .bord-white {
      border-bottom: 1px white !important;
      outline: white !important;
    }
    /* ion-icon {
      font-size: 30px;
    } */

    item-native {
      background-color: red;
    }

    ion-item-list {
      background-color: transparent;
    }
    .full {
      width: 100%;
    }
    .shadow {
      box-shadow: -1px 3px 5px 0px rgba(61, 61, 61, 0.29) !important;
    }
    .w100 {
      width: 100px;
    }
    .w150 {
      width: 150px;
    }
    .w200 {
      width: 200px;
    }
    .w50 {
      width: 50px !important;
    }
    pt-100 {
      padding-top: 100px;
    }
    .h100 {
      height: 5em;
    }

    ion-content ion-toolbar {
      --background: translucent;
    }

    ion-card {
      min-width: 300px;
    }
    ion-col {
      min-width: 320px;
    }
    six-steps {
      padding: 0px 200px;
    }

    h1,
    h2 {
      font-family: "Montserrat", sans-serif;
      font-weight: 700 !important;
      font-size: 38px !important;
    }

    h3 {
      font-family: "Montserrat", sans-serif;
      font-weight: 700 !important;
      font-size: 24px !important;
    }
    h4,
    h5,
    h6 {
      font-family: "Montserrat", sans-serif;
      font-weight: 600 !important;
      font-size: 18px !important;
    }

    body {
      font-family: "Open Sans", sans-serif !important;
    }

    .bkg-trans {
      background-color: transparent !important;
    }

    .lime {
      background-color: #57c6aa !important;
    }

    .teal {
      background-color: #57b9c6;
      color: #57b9c6;
    }
    .dark-teal {
      background-color: #3b8eaa;
      color: #3b8eaa;
    }

    .teal-text {
      color: #57b9c6;
    }
    .marg {
      margin: auto;
    }
    .landing-dark-blue-text {
      color: #104c82;
    }

    .landing-dark-blue {
      background-color: #104c82 !important;
    }
    .bord-10 {
      border-radius: 10px;
    }
    .bord-20 {
      border-radius: 20px;
    }
    .bord-5 {
      border-radius: 5px;
    }
    .white-text {
      color: white;
    }
    .pb-0 {
      padding-bottom: 0px !important;
    }
    ion-button {
      font-family: "Montserrat", sans-serif;
    }

    /* MY MOD */

    .mymod {
      width: 100%;
    }

    modal-wrapper sc-ion-modal-md {
      width: 800px !important;
    }
    // }
  }
  .home-to-home-container {
    * {
      scroll-behavior: smooth;
    }

    // .tab-container {
    .bord-white {
      border-bottom: 1px white !important;
      outline: white !important;
    }
    /* ion-icon {
      font-size: 30px;
    } */

    item-native {
      background-color: red;
    }

    ion-item-list {
      background-color: transparent;
    }
    .full {
      width: 100%;
    }
    .shadow {
      box-shadow: -1px 3px 5px 0px rgba(61, 61, 61, 0.29) !important;
    }
    .w100 {
      width: 100px;
    }
    .w150 {
      width: 150px;
    }
    .w200 {
      width: 200px;
    }
    .w50 {
      width: 50px !important;
    }
    pt-100 {
      padding-top: 100px;
    }
    .h100 {
      height: 5em;
    }

    ion-content ion-toolbar {
      --background: translucent;
    }

    ion-card {
      min-width: 300px;
    }
    ion-col {
      min-width: 320px;
    }
    six-steps {
      padding: 0px 200px;
    }

    h1,
    h2 {
      font-family: "Montserrat", sans-serif;
      font-weight: 700 !important;
      font-size: 38px !important;
    }

    h3 {
      font-family: "Montserrat", sans-serif;
      font-weight: 700 !important;
      font-size: 24px !important;
    }
    h4,
    h5,
    h6 {
      font-family: "Montserrat", sans-serif;
      font-weight: 600 !important;
      font-size: 18px !important;
    }

    body {
      font-family: "Open Sans", sans-serif !important;
    }

    .bkg-trans {
      background-color: transparent !important;
    }

    .lime {
      background-color: #57c6aa !important;
    }

    .teal {
      background-color: #57b9c6;
      color: #57b9c6;
    }
    .dark-teal {
      background-color: #3b8eaa;
      color: #3b8eaa;
    }

    .teal-text {
      color: #57b9c6;
    }
    .marg {
      margin: auto;
    }
    .landing-dark-blue-text {
      color: #104c82;
    }

    .landing-dark-blue {
      background-color: #104c82 !important;
    }
    .bord-10 {
      border-radius: 10px;
    }
    .bord-20 {
      border-radius: 20px;
    }
    .bord-5 {
      border-radius: 5px;
    }
    .white-text {
      color: white;
    }
    .pb-0 {
      padding-bottom: 0px !important;
    }
    ion-button {
      font-family: "Montserrat", sans-serif;
    }

    /* MY MOD */

    .mymod {
      width: 100%;
    }

    modal-wrapper sc-ion-modal-md {
      width: 800px !important;
    }
  }
  .learn-more-row {
    &.mobile {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .learn-more-col {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
      &.mobile {
        padding: 0px 40px;
      }
      .learn-more-content-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-self: center;
        .learn-more-title {
          color: #104c82;
          font-size: 42px;
          font-weight: bold;
          max-width: 440px;
        }
        .learn-more-description {
          margin-top: 20px;
          max-width: 440px;
        }
        .learn-more-button {
          margin-top: 40px;
        }
      }
    }
    .learn-more-phone-col {
      padding-top: 20px;
    }

    .phone-img {
      height: 550px;
      &.mobile {
        height: auto;
      }
    }
  }
  .pre-reg-form-col {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
  }
  .pre-reg-footer {
    position: absolute;
    bottom: 0px;
    max-height: 52px;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }

  .gray {
    background-color: #fafafa;
  }

  .preregister-icon {
    font-size: 20px;
    opacity: 0.5;
  }
  .newsletter-item {
    --background: rgba(9, 172, 189, 0.18) !important;
    .newsletter-label {
      white-space: normal !important;
      color: rgba(9, 172, 189, 1) !important;
    }
  }
  .goto-home-pre-reg-button {
    height: 45px;
  }
  .submit-pre-reg-button {
    height: 45px;
  }

  .logo-padding {
    padding-left: 40px;
  }

  .doctor-create-password-privacy {
    margin-left: 5px;
  }
}

.pre-reg-success-dialog {
  --height: auto;
  --width: auto;
  --max-width: 500px;
  --border-radius: 20px;
  --box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  ion-backdrop {
    background: #104c82 !important;
  }
  .modal-wrapper {
    max-width: 100%;
    width: 500px;
    .ion-page {
      position: relative;
      contain: content;
      max-height: 90vh;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  .pre-reg-dialog-children-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px;
    .pre-reg-dialog-title-label {
      margin-top: 15px;
      margin-bottom: 15px;
      text-align: center;
    }
    .pre-reg-dialog-message-label {
      text-align: center;
      margin: 20px 0px;
    }
    .pre-reg-icon {
      background: url("../../assets/icons/pre-reg-dialog.svg") no-repeat center
        center;
      height: 219px;
      width: 238px;
    }
    .pre-reg-dialog-action-button {
      height: 46px !important;
      width: 176px;
      margin-top: 55px;
      filter: drop-shadow(0px 8px 21px rgba(87, 185, 198, 0.5));
    }
  }
}

.pre-reg-input-item {
  --padding-start: 0px;
}
.pregistration-dropdown-container {
  width: 100%;
  // &.mobile {
  //   width: 295px !important;
  // }
}
.survey-note {
  margin-bottom: 10px;
}

@media (max-width: 414px) {
  .bkg-gray {
    .preregistration {
      padding: 10px 25px !important;
      margin: 10px;
      min-width: unset;
      max-height: 622px;
    }
  }
  // .pregistration-dropdown-container {
  //   width: 402px !important;
  // }
}
