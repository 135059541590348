@import "../../theme/shared.scss";

.mb-appointment-list-dialog {
  --height: auto;
  --width: auto;
  --max-width: 1250px;
  --border-radius: 20px;
  --box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  ion-backdrop {
    background: #104c82 !important;
  }
  .modal-wrapper {
    max-width: 100%;
    width: 1250px;
    max-height: 750px;
    .ion-page {
      position: relative;
      contain: content;
      max-height: 90vh;
      overflow-y: scroll;
      background: var(--ion-color-tertiary);
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  .mb-appointment-list-dialog-children-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .mb-appointment-list-dialog-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 25px 49px;
      border-bottom: 1px solid var(--ion-color-medium);
      width: 100%;
      &.mobile {
        padding: 15px 10px 15px 30px;
      }
      .mb-appointment-list-dialog-title-label {
        text-align: left;
      }
    }

    .mb-appointment-list-dialog-content {
      padding: 20px 50px;
      width: 100%;
      &.mobile {
        padding: 20px 10px;
      }
      .mb-appointment-list-dialog-details {
        display: flex;
        flex-direction: column;

        .mb-appointment-list-dialog-details-header {
          padding: 20px;
          margin: 5px;
          box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.22);
          background: var(--ion-color-tertiary);
          border-radius: 4px;
          display: flex;
          justify-content: space-between;
          .mb-appointment-list-dialog-details-checkbox-container {
            display: flex;
            align-items: center;
            .mb-appointment-list-dialog-details-select-all-checkbox {
              margin-right: 20px;
            }
          }

          .mb-appointment-list-dialog-details-delete {
            height: 30px;
          }
        }

        .mb-appointment-list-appointments-container {
          margin-top: 10px;
          max-height: 550px;
          overflow-y: auto;
          padding: 5px;
          padding-bottom: 50px;
        }
      }
    }
  }
}
