.doctor-home-dashboard-card-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  &.mobile {
    flex-direction: column !important;
  }
}
.doctor-home-dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  &.mobile {
    margin: 40px !important;
  }
  .doctor-home-icon {
    background: url("../../assets/doctor-home-logo.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: center;
    width: 399px;
    height: 274px;
    &.mobile {
      background-size: contain !important;
      width: 299px !important;
    }
  }
  .exec-assistant-home-icon {
    background: url("../../assets/executive-assistant-home-logo.svg") no-repeat
      center center;
    width: 307px;
    height: 211px;
    &.mobile {
      background-size: contain !important;
      width: 299px !important;
    }
  }

  .doctor-home-title {
    margin-top: 30px;
    margin-bottom: 10px;
    text-align: center;
  }
  .doctor-home-description {
    text-align: center;
  }
}
.doctor-home-button-container {
  margin-top: 25px;
  margin-bottom: 25px;
  .doctor-home-goto-button {
    width: 200px;
    height: 42px;
    border-radius: 5px;
  }
  .doctor-home-add-doctor-services {
    width: 375px;
    height: 42px;
  }
}
