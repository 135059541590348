.forgot-password-form-card {
  max-width: 529px;
  max-height: 608px;
  max-width: 529px;
  width: 100%;
  &.mobile {
    width: 100%;
    margin-top: 250px;
    margin-bottom: 20px;
  }

  .forgot-password-form-card-content {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 32px 15px;
    max-width: 529px;
    .forgot-password-form-card-logo {
      background: url(../../assets/mb-card-logo.svg) no-repeat center;
      width: 396px;
      height: 100px;
      margin-bottom: 12px;
      align-self: center;
      &.mobile {
        margin: 0 auto 12px;
        background: url(../../assets/mobile-mb-card-logo.svg) no-repeat center;
        width: 280px;
        height: 76px;
        margin: 0 auto 12px;
        width: 100%;
      }
    }
    .forgot-password-form-details {
      display: flex;
      flex-direction: column;
      text-align: center;
      padding: 0 15px;
      max-width: 420px;
      align-self: center;
      .forgot-password-description {
        margin-top: 15px;
      }
      .forgot-password-form-input-container {
        display: flex;
        flex-direction: column;
        .forgot-password-form-email-input {
          margin-top: 30px;
        }
        .forgot-password-form-forgot-resend-seconds {
          margin-top: 39px;
          margin-bottom: 30px;
        }
      }
      .forgot-password-form-send-button {
        max-width: 420px;
        width: 100%;
        margin-top: 12px;
        height: 43px;
        filter: drop-shadow(0px 8px 21px rgba(87, 185, 198, 0.5));
      }
      .forgot-password-form-support-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 15px;
        justify-content: center;
        .mb-forgot-password-form-email-support {
          height: 13px;
          margin-left: 3px;
        }
      }
    }
  }
}
