.patient-view-resource-container {
  width: 100%;
  height: 100%;

  .patient-view-resource-grid {
    height: 100%;
    .patient-view-resource-row {
      height: 100%;

      .patient-view-resource-col {
        padding-top: 20px;
      }
      .patient-view-resource-details-card {
        background: var(--ion-color-tertiary);
        .patient-view-resource-details-card-header {
          display: flex;
          justify-content: flex-end;
          background: var(--ion-color-tertiary);
        }
        .patient-view-resource-details-card-content {
          padding: 59px;
          display: flex;
          flex-direction: column;
          align-items: center;
          background: var(--ion-color-tertiary);
          &.mobile {
            padding: 20px;
          }
          .patient-view-resource-doctor-icon {
            height: 144px;
            width: 144px;
            background: url(../../assets/icons/detail-doctor-resource-big.svg)
              no-repeat center center;
          }

          .services-view-resource-doctor-icon {
            align-self: center;
            height: 143px;
            width: 143px;
            padding: 0 32px 0 20px;
            background: url(../../assets/icons/detail-service-resource-big.svg)
              no-repeat center center;
          }

          .patient-view-resource-name {
            margin-top: 35px;
          }

          .patient-view-resource-hospital-name {
            margin-top: 5px;
          }

          .patient-view-resource-weekly-label {
            opacity: 0.3;
            margin-top: 35px;
            margin-bottom: 15px;
          }

          .patient-view-resource-schedule-days-container {
            margin-top: 15px;
          }

          .patient-view-resource-card-status {
            background: var(--ion-color-success);
            border-radius: 5px;
            height: 25px;
            width: 155px;
            padding-top: 2px;
            text-align: center;
            margin-top: 50px;
            &.not-available {
              background: rgba(196, 196, 196, 0.25) !important;
            }
          }

          .patient-view-resource-fee-description-section {
            width: 100%;
            background: var(--ion-background-color);
          }

          .patient-view-resource-fee-description-section {
            margin-top: 21px;

            .patient-view-resource-fee-description-header {
              padding: 13px 20px;
              display: flex;
              justify-content: space-between;
              border-bottom: 1px solid var(--ion-color-medium);
            }
            .patient-view-resource-fee-description-body {
              padding: 23px 20px;
            }
          }
        }
      }

      .patient-view-resource-booking-options-card {
        background: var(--ion-color-tertiary);
        .patient-view-resource-booking-options-card-header {
          padding: 25px;
        }

        .patient-view-resource-booking-options-card-content {
          margin: 25px;
          &.mobile {
            margin: 15px;
          }
          .patient-view-resource-date-input {
            border-bottom: 1px solid var(--ion-color-primary);
          }

          .patient-view-resource-drop-down-container {
            margin-top: 30px;
            width: 100%;
            display: flex;
            flex-direction: column;
            .add-member-button {
              align-self: flex-end;
            }
            .patient-view-resource-select-schedule {
              border-bottom: 1px solid var(--ion-color-primary);
            }
            margin-bottom: 20px;
          }
          .patient-view-resource-condition-container {
            margin-bottom: 30px;
          }
          .patient-view-resource-booking-for-label {
            margin-bottom: 15px;
          }
          .patient-view-resource-booking-for-container {
            width: 100%;
          }

          .patient-view-resource-booking-fee-container {
            background: var(--ion-background-color);
            border-radius: 4px;
            margin-top: 30px;
            padding: 15px 20px;
            display: flex;
            justify-content: space-between;
          }
        }

        .patient-view-resource-footer-container {
          padding: 25px;
          display: flex;
          flex-direction: column;
          border-top: 1px solid var(--ion-color-medium);

          .patient-view-resource-submit-button {
            --background: var(--ion-color-success);
          }
        }
      }
    }
  }
}
