.login-form-card {
  max-width: 529px;
  max-height: 608px;
  max-width: 529px;
  width: 100%;
  &.mobile {
    width: 100%;
    margin-top: 150px;
    margin-bottom: 20px;
  }

  .login-form-card-content {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 32px 15px;
    max-width: 529px;
    .login-form-card-logo {
      background: url(../../assets/mb-card-logo.svg) no-repeat center;
      width: 396px;
      height: 100px;
      margin-bottom: 12px;
      align-self: center;

      &.mobile {
        margin: 0 auto 12px;
        background: url(../../assets/mobile-mb-card-logo.svg) no-repeat center;
        width: 280px;
        height: 76px;
        margin: 0 auto 12px;
        width: 100%;
      }
    }
    .login-form-details {
      display: flex;
      flex-direction: column;
      text-align: center;
      padding: 0 15px;
      .login-form-input-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        .login-form-email-input {
          margin-top: 20px;
          max-width: 416px;
          width: 100%;
        }
        .login-form-password-input {
          margin-top: 19px;
          max-width: 416px;
          width: 100%;
        }
        .login-form-forgot-password-link {
          text-decoration: none;
          text-align: start;
          margin-top: 25px;
          margin-bottom: 20px;
          align-self: start;
          margin-left: 25px;
        }
      }
      .login-form-login-button {
        max-width: 420px;
        width: 100%;
        margin-top: 12px;
        height: 43px;
        filter: drop-shadow(0px 8px 21px rgba(87, 185, 198, 0.5));
      }
      .login-form-register-button {
        margin-top: 15px;
        height: 43px;
        max-width: 420px;
        width: 100%;
        --border-width: 1px;
        --border-style: solid;
        --border-color: var(--ion-color-primary);
      }
      .login-form-notice-label {
        margin-top: 25px;
        a {
          color: var(--ion-color-light) !important;
        }
      }
    }
  }
}
