.landing-page-container {
  &.ion-page-invisible {
    opacity: 1 !important;
  }
  .landing-content {
    position: relative;
    height: calc(100% - 70px);

    /* background: red; */
    .ion-page-invisible {
      opacity: 1 !important;
    }
  }

  .landing-segment {
    background: var(--ion-color-tertiary);
    height: 70px;
    position: absolute;
    bottom: 0;
  }
}

ion-title {
  cursor: pointer;
  width: fit-content;
}
