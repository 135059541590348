.landing-footer {
  width: 100%;
  z-index: 999 !important;
  .no-underline {
    text-decoration: none;
  }
  &.landing-dark-blue {
    background-color: #104c82 !important;
    border-top: 1px solid #03264a3d;
  }
}
